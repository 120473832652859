import React, { useEffect } from 'react';
import { SettingAccountForm } from './components/SettingAccountForm';
import { SettingAccountAvatar } from './components/SettingAccountAvatar';

import { CardSection } from '../../shared/components/card/CardSection';
import { Box /**, Theme */, Typography } from '@mui/material';
import { updateProfileStoreAction } from './setting-actions';

export const SettingAccountView: React.FC = () => {
  //const { isChanged } = useStore(updateProfileStore);
  //const classes = useStyles();
  useEffect(() => {
    updateProfileStoreAction(true);
  }, []);
  return (
    <>
      <SettingAccountAvatar />
      <CardSection
        title={
          <Typography variant="h6" fontWeight={800} textTransform="uppercase">
            Datos de tu cuenta
          </Typography>
        }
      >
        <Box p={2}>
          <SettingAccountForm />
        </Box>
      </CardSection>
    </>
  );
};

import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
  Hidden,
  Drawer,
  Button,
  ListItemIcon,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { SETTING_ROUTES } from './layout-utils';
import { PRIMARY_COLOR } from '../../../css/theme';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { closeMobileMenu } from './user-actions';
import { RoleAuthorization } from '../../../../modules/rbac/RoleAuthorization';
import { RBACPermissions } from '../../../../modules/rbac/rbac-types';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: PRIMARY_COLOR,
    },
    textNormal: {
      '&:hover': {
        color: PRIMARY_COLOR,
        cursor: 'pointer',
      },
    },
    listItem: {
      '& > span': {
        fontWeight: 'bold',
      },
    },
    helpCenter: {
      color: 'black',
      '&:hover': {
        color: '#FA0000',
      },
    },
  }),
);

export const Menu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  return (
    <>
      <Hidden mdDown={false} mdUp={true}>
        <Drawer anchor="left" open={open} onClose={() => closeMobileMenu()}>
          <Box
            sx={{ width: 250 }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            height={'100%'}
            onClick={() => closeMobileMenu()}
          >
            <Box width={'100%'}>
              <ListItem divider>
                <Typography color="primary" fontWeight="bold">
                  TU CONTENIDO
                </Typography>
              </ListItem>
              {SETTING_ROUTES.map((route, key) => {
                return (
                  <ListItem
                    divider
                    key={'setting routes map-' + key}
                    className={
                      route.path === location.pathname
                        ? classes.textRed
                        : classes.textNormal
                    }
                    onClick={() => {
                      navigate(route.path);
                    }}
                  >
                    <ListItemText primary={route.name} />
                    <ArrowForwardIosIcon fontSize="small" />
                  </ListItem>
                );
              })}

              <List component="nav" aria-label="mailbox folders">
                <ListItem>
                  <Typography fontWeight="bold">SOPORTE</Typography>
                </ListItem>
                <Divider />
                <a
                  className={classes.helpCenter}
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                  target="_blank"
                  href="https://ac.noroeste.com.mx/autoayuda"
                >
                  <ListItem divider>
                    <ListItemText
                      style={{ fontSize: '16px' }}
                      primary="Centro de ayuda"
                    />
                    <ArrowForwardIosIcon fontSize="small" />
                  </ListItem>
                </a>
              </List>
              <List component="nav" aria-label="mailbox folders">
                <ListItem>
                  <RoleAuthorization
                    render={() => (
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{ textTransform: 'capitalize', height: 45 }}
                        onClick={() => navigate('/suscripciones')}
                        fullWidth
                      >
                        <Typography style={{ color: 'white' }}>
                          <Hidden lgDown={true} lgUp={false}>
                            Quiero{' '}
                          </Hidden>
                          acceso total
                        </Typography>
                      </Button>
                    )}
                    permission={RBACPermissions.CAN_SUBSCRIPTION}
                    error={() => null}
                  />
                </ListItem>
              </List>
            </Box>
            <Box>
              <div style={{ marginTop: '100px' }}>
                <Divider />
                <ListItem
                  className={classes.textNormal}
                  onClick={() => navigate('/logout')}
                >
                  <ListItemIcon>
                    <PowerSettingsNewSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </ListItem>
              </div>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} mdUp={false}>
        <Box>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography color="primary" fontWeight="bold">
                TU CONTENIDO
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route, key) => {
              return (
                <ListItem
                  divider
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                  key={'setting routes map-' + key}
                >
                  <ListItemText primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}
          </List>

          <List component="nav" aria-label="mailbox folders">
            <ListItem>
              <Typography fontWeight="bold">SOPORTE</Typography>
            </ListItem>
            <Divider />
            <a
              className={classes.helpCenter}
              style={{ textDecoration: 'none' }}
              target="_blank"
              href="https://ac.noroeste.com.mx/autoayuda"
            >
              <ListItem divider>
                <ListItemText
                  style={{ fontSize: '16px' }}
                  primary="Centro de ayuda"
                />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
            </a>
            <div style={{ marginTop: '100px' }}>
              <Divider />
              <ListItem
                className={classes.textNormal}
                onClick={() => navigate('/logout')}
              >
                <ListItemIcon>
                  <PowerSettingsNewSharpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItem>
            </div>
          </List>
        </Box>
      </Hidden>
    </>
  );
};

import React, { ChangeEvent, useState } from 'react';
import {
  Education,
  Gender,
  UpdateUserMutationVariables,
  useDisableAccountMutation,
  User,
} from '../../../shared/types/generated';
import { useAuth } from '../../auth/auth-hook';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { updateUserAction } from '../../user/user-actions';
import { openDialogAlert } from '../../alert/alert-actions';
import {
  Box,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { USER_EDUCATION_LIST, USER_GENDER_LIST } from '../setting-utils';
import { SelectDefault } from '../../../shared/components/input/SelectDefault';
import { changeAvatarStore } from '../setting-events';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { changeEmailDialogStore } from '../setting-stores';
import { changeEmailDialogStoreAction } from '../setting-actions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
  }),
);

const StyledButton = styled(ButtonDefault)({
  backgroundColor: PRIMARY_COLOR,
  border: 'none!important',
  color: 'white!important',
  width: 200,
});
type ChangeEmailData = {
  email: string;
  password: string;
};
export const SettingAccountForm: React.FC = () => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const { open } = useStore(changeEmailDialogStore);
  const navigate = useNavigate();
  const { file } = useStore(changeAvatarStore);
  const [changeEmailData, setChangeEmailData] = useState<ChangeEmailData>({
    email: '',
    password: '',
  });
  const [userData, setUserData] = useState<User>({
    id: 0,
    name: '',
    password: '',
    gender: Gender.Female,
    email: '',
    birthday: '',
    ...user,
  });
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [updateUser, loading] = useCallAction(updateUserAction, {
    onCompleted: () => {
      refetch();
      return openDialogAlert('Se ha actualizado tu perfil correctamente.');
    },
    onError: (error) => {
      return openDialogAlert(error.message);
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setUserData({
      ...userData,
      [event.target.name as keyof User]: event.target.value as string,
    });
  };
  const [disableUser, { loading: loadingdisableUser }] =
    useDisableAccountMutation({
      onCompleted: () => {
        openDialogAlert(
          'Se ha eliminado tu perfil lamentamos tu partida.',
          () => navigate('/logout'),
        );
        return;
      },
      onError: (error) => {
        return openDialogAlert(error.message);
      },
    });
  const handleUpdate = (): void => {
    if (userData.password && userData.password !== repeatPassword) {
      return openDialogAlert('Las contraseñas deben coincidir');
    }

    const { id, name, password, gender, birthday, email, education } = userData;
    let variables: UpdateUserMutationVariables = {
      data: {
        user: {
          id,
          name,
          gender,
          birthday,
          email,
          education,
        },
      },
    };

    if (password) {
      variables = {
        data: {
          user: { ...(variables.data?.user as User), password },
        },
      };
    }

    if (file) {
      variables = {
        data: {
          ...variables.data,
          file,
        },
      };
    }

    updateUser(variables);
  };
  const onChangeHandlerEmail = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setChangeEmailData({
      ...changeEmailData,
      [event.target.name as keyof ChangeEmailData]: event.target
        .value as string,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder={'Nombre de usuario'}
          name="name"
          fullWidth
          value={decodeURI(userData.name)}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder={'Email'}
          name="email"
          fullWidth
          value={userData?.email}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          disabled={!!user?.email}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="Contraseña"
          name="password"
          type="password"
          fullWidth
          value={userData.password as string}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="Repetir Contraseña"
          name="repeatPassword"
          type="password"
          fullWidth
          value={repeatPassword}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => {
            setRepeatPassword(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectDefault
          placeholder="Sexo"
          variant="outlined"
          options={USER_GENDER_LIST}
          name={'gender'}
          defaultValue={user?.gender}
          InputProps={{
            className: classes.input,
          }}
          onChangeData={(field, value) => {
            setUserData({
              ...userData,
              [field]: value as Gender,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          placeholder="Fecha de nacimiento"
          name="birthday"
          type="date"
          fullWidth
          value={userData?.birthday}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          onChange={(event) => {
            setUserData({
              ...userData,
              birthday: event.target.value as Gender,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectDefault
          placeholder="Escolaridad"
          variant="outlined"
          options={USER_EDUCATION_LIST}
          name={'education'}
          value={userData?.education}
          InputProps={{
            className: classes.input,
          }}
          onChangeData={(field, value) => {
            setUserData({
              ...userData,
              [field]: value as Education,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {!(user?.name === userData.name) ||
        !(user?.email === userData.email) ||
        !(user?.id === userData.id) ||
        !(user?.gender === userData.gender) ||
        !(user?.birthday === userData.birthday) ||
        userData.password ? (
          <Box
            mt={'15px'}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            width={'full'}
          >
            <StyledButton
              isLoading={loading}
              style={{ width: 200, height: 45 }}
              onClick={() => handleUpdate()}
            >
              Guardar cambios
            </StyledButton>
          </Box>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Box my={'15px'} paddingLeft={2}>
          <Divider style={{ marginBottom: '10px' }} />
          <Typography marginBottom={2}>
            En cualquier momento si usted asi lo considera puede eliminar su
            cuenta. Toda su información será eliminada de nuestros servidores.
          </Typography>
          <StyledButton
            isLoading={loadingdisableUser}
            onClick={() => changeEmailDialogStoreAction(true)}
          >
            Eliminar cuenta
          </StyledButton>

          <DialogDefault
            isOpen={open}
            handleClose={() => changeEmailDialogStoreAction(false)}
            title="Eliminar cuenta en Noroeste."
            isLoading={loadingdisableUser}
            showActions
            handleSuccess={() => {
              disableUser({
                variables: {
                  data: {
                    email: changeEmailData.email,
                    password: changeEmailData.password,
                  },
                },
              });
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder={'Correo electrónico'}
                  name="email"
                  fullWidth
                  value={changeEmailData?.email}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 19 },
                  }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={(event) => onChangeHandlerEmail(event)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  placeholder="Contraseña"
                  name="password"
                  type="password"
                  fullWidth
                  value={changeEmailData.password as string}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 19 },
                  }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={(event) => onChangeHandlerEmail(event)}
                  helperText="Ingrese su contraseña para confirmar que deseas eliminar tu cuenta. Al hacer clic en el botón de confirmár, se eliminará permanentemente toda la información asociada a tu cuenta. Esta acción no se puede deshacer."
                />
              </Grid>
            </Grid>
          </DialogDefault>
        </Box>
      </Grid>
    </Grid>
  );
};
